import { ConfirmationModal, useConfirmationModal } from "@synota-io/synota-shared-ui";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import AppRoutes from "./AppRoutes";
import { IS_PRODUCTION } from "./utils/environment";

const VERSION_CHECK_TIME = 1000 * 5 * 60;

export const App = () => {
  const modal = useConfirmationModal();

  const versionCheck = useQuery({
    queryKey: ["app-version-check"],
    queryFn: async () => {
      const res = await fetch("/version.json");
      return res.json();
    },
    enabled: IS_PRODUCTION,
    refetchInterval: VERSION_CHECK_TIME,
    gcTime: 0,
    staleTime: 0,
  });

  useEffect(() => {
    if (versionCheck.data && versionCheck.data.version.trim() !== APP_VERSION) {
      modal.onOpen({
        onConfirm: () => {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        },
        acceptLabel: "Reload Page",
        title: "A new version of Synota is available!",
      });
    }
  }, [modal, versionCheck.data, versionCheck.isFetching]);

  return (
    <>
      <AppRoutes />
      <ConfirmationModal {...modal} />
    </>
  );
};
